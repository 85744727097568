import React from 'react';

const Articles = (props) => {
    return (
        <section className="blog_sec">
				<div className="container">
					<div className="tophead">
						<h2 className="title1">
							Latest Articles & Tips
						</h2>  
					</div>
					<div className="blog_panel">
						<div className="row">
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<article className="blogdiv wow fadeIn" data-wow-delay="0s" data-wow-delay="0.5s">
									<span className="img">
										<img src={ require("../../../assets/images/blog1.jpg") } alt=""/>
									</span>
									<div className="blogtext">
										<h3>
											<a href="https://blog.carryon.bike/cycling-will-be-a-thriving-industry-across-the-globe-post-corona-2/">Cycling will be a thriving industry across the globe post Corona</a></h3>
										<div className="ardetails clear">
											<span className="date">july 11, 2020</span>
											<span className="icolist">
												<a className="wslst" href="#">
													<i className="fa fa-heart" aria-hidden="true"></i> 69
												</a>
												<a className="chat" href="#">
													<i className="fa fa-commenting" aria-hidden="true"></i> 01
												</a>
											</span>
										</div>
										<p>Shreya was inspired by the biking culture in the Netherlands since long. </p>
									</div>
								</article>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<article className="blogdiv wow fadeIn" data-wow-delay=".5s" data-wow-delay="0.5s">
									<span className="img">
										<img src={ require("../../../assets/images/blog2.jpg") } alt=""/>
									</span>
									<div className="blogtext">
										<h3><a href="https://blog.carryon.bike/race-around-austria-challenge-the-leap-into-the-unknown/">Race Around Austria Challenge – the leap into the unknown</a></h3>
										<div className="ardetails clear">
											<span className="date">July 11, 2020</span>
											<span className="icolist">
												<a className="wslst" href="#">
													<i className="fa fa-heart" aria-hidden="true"></i> 69
												</a>
												<a className="chat" href="#">
													<i className="fa fa-commenting" aria-hidden="true"></i> 01
												</a>
											</span>
										</div>
										<p>A Diploma Meteorologist, has always been passionate about Weather, Cycle and Mountains all her life.</p>
									</div>
								</article>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<article className="blogdiv wow fadeIn" data-wow-delay="1s" data-wow-delay="0.5s">
									<span className="img">
										<img src={ require("../../../assets/images/blog3.jpg") } alt=""/>
									</span>
									<div className="blogtext">
										<h3><a href="https://blog.carryon.bike/there-is-no-such-thing-as-bad-weather-just-poor-planning/">There is no such thing as bad weather, just poor planning!</a></h3>
										<div className="ardetails clear">
											<span className="date">July 16, 2020</span>
											<span className="icolist">
												<a className="wslst" href="#">
													<i className="fa fa-heart" aria-hidden="true"></i> 69
												</a>
												<a className="chat" href="#">
													<i className="fa fa-commenting" aria-hidden="true"></i> 01
												</a>
											</span>
										</div>
										<p>There is always an adventure to be had on your bicycle. You can plan your trail using a route planning app like Strava,</p>
									</div>
								</article>
							</div>
							<div className="col-lg-6 col-md-12 col-sm-12 col-12">
								<article className="blogdiv wow fadeIn" data-wow-delay="1.5s" data-wow-delay="0.5s">
									<span className="img">
										<img src={ require("../../../assets/images/image30.jpg") } alt=""/>
									</span>
									<div className="blogtext">
										<h3><a href="https://blog.carryon.bike/bicycling-boom-during-lockdown/">Bicycling Boom During Lockdown!</a></h3>
										<div className="ardetails ">
											<span className="date">July 27, 2020</span>
											<span className="icolist">
												<a className="wslst" href="#">
													<i className="fa fa-heart" aria-hidden="true"></i> 69
												</a>
												<a className="chat" href="#">
													<i className="fa fa-commenting" aria-hidden="true"></i> 01
												</a>
											</span>
										</div>
										<p>With 2020, came a pandemic which put the world on a pause and encaged humans in their homes.</p>
									</div>
								</article>
							</div> 
						</div>
						<div className="btnholder">
							<a className="btn" href="#">
								<span>view all</span>
							</a>
						</div>
					</div>
				</div>
			</section>
    )
}

export default Articles;
